
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import useThousands from "@/utils/payment/useThousands";
import {
  SampleCheckConfirm,
  SampleCheckConfirmResult,
  SampleCheckPayoutRoundConfirm,
  SampleCheckPayoutRoundConfirmResult,
} from "@/API/checking/spotChecking";
export default defineComponent({
  name: "App",
  props: {
    // payoutCodeId/packageCodeId
    id: {
      type: String,
      default: "",
    },
    ids: {
      type: Array,
    },
    flType: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "dealer",
    },
    offerTypeIds: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const _visible = ref(false);
    const column = ref([
      {
        title: "No.",
        slots: {
          customRender: "Index",
        },
      },
      { title: "Program Code", dataIndex: "programCode" },
      { title: "Passed Vin no.", dataIndex: "vinNo" },
      { title: "Passed Amount", dataIndex: "totalFinalAmount" },
    ]);
    const dataRes = ref();
    const search = () => {
      if (props.type === "dealer") {
        if (!props.ids || (props.ids && props.ids.length === 0)) {
          message.warning("Please select the data first.");
          return false;
        }
        SampleCheckConfirmResult(props.offerTypeIds, props.id).then((res) => {
          if (res.code === "0") {
            dataRes.value = res.data;
            _visible.value = true;
          } else {
            // message.error(res.message);
          }
          //  && res.data.length > 0) {
          //   _visible.value = true;
        });
      } else {
        SampleCheckPayoutRoundConfirmResult(props.id, props.flType).then(
          (res) => {
            if (res.code === "0") {
              dataRes.value = res.data;
              _visible.value = true;
            } else {
              // message.error("error");
            }
          }
        );
      }
    };
    const handleConfirm = () => {
      if (props.type === "dealer") {
        if (props.ids && props.offerTypeIds && props.id) {
          // const params = {
          //   packageProgramOfferTypeIds: props.offerTypeIds,
          // };
          SampleCheckConfirm(props.offerTypeIds, props.id).then((res) => {
            _visible.value = false;
            context.emit("on-confirm");
            message.success("Confirm Sucessfully");
          });
        }
      } else {
        SampleCheckPayoutRoundConfirm(props.id, props.flType).then((res) => {
          _visible.value = false;
          context.emit("on-confirm");
          message.success("Confirm Sucessfully");
        });
      }
    };
    const handleCancel = () => {
      _visible.value = false;
    };
    return {
      column,
      dataRes,
      search,
      handleConfirm,
      handleCancel,
      _visible,
    };
  },
});
