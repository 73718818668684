export const spotCheckSampledOptions = [
  {
    name: "Vendor un-Sampled",
    value: "0",
  },
  {
    name: "Vendor Sampled",
    value: "1",
  },
  // {
  //   name: "All",
  //   value: "",
  // }
];
export const controllingSampledOptions = [
  {
    name: "Controlling un-Sampled",
    value: "0",
  },
  {
    name: "Controlling Sampled",
    value: "1",
  },
  {
    name: "All",
    value: "-1",
  }
];
export const checkStatusOptions = [
  {
    name: "Spot Checking Prepared",
    value: "1",
  },
  {
    name: "Logic Confirmed",
    value: "2",
  },
  {
    name: "Sample Confirmed",
    value: "3",
  },
  {
    name: "Controlling Reviewed",
    value: "4",
  },
  // {
  //   name: "All",
  //   value: "",
  // }
];
